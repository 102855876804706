.form-control{
    margin: 0 !important;
    min-height: 0;
    width: 100%;
}


.form-control-sm {
  min-height: 0;
  height: 24px !important;
}

.form-control-md {
  min-height: 0;
  height: 33.5px !important;
}

.form-group{
    margin: 8px 0 16px 0 !important;
}

.form-control-md {
  height: 33.5px;
  padding: 0 0.75rem; 
  font-size: 1rem; 
  border-radius: 0.375rem;
}
  
  .form-control-md::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
  }
  
  .form-control-md::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
  }
  
  

.position-relative {
    position: relative;
  }
  
  .input-group {
    display: flex;
    align-items: center;
  }
  
  .input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-45%);
    pointer-events: none;
    color: #6c757d;
    
  }
  
  .date-picker {
    padding-right: 30px;
  }
  
  .form-label{
    font-weight: 700 !important;
  }

  .extra-component{
    display: flex;
    gap: 8px;
}